// 可以根据路由模式的不同，后面俩可以只引用一个
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'


// 构建我们的页面路由配置，可以看到，这里和原来的写法并无二致。
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/homepage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue'),
    props: true
  },
  {
    path: '/news_list/:id',
    name: 'newslist',
    component: () => import('../views/news.vue'),
    props: true
  },
  {
    path: '/news_info/:id',
    name: 'newsinfo',
    component: () => import('../views/newsinfo.vue'),
    props: true
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('../views/goods.vue'),
    props: true
  },
  {
    path: '/goods_list/:id',
    name: 'goodslist',
    component: () => import('../views/goods.vue'),
    props: true
  },
  {
    path: '/goods_info/:id',
    name: 'goodsinfo',
    component: () => import('../views/goodsinfo.vue'),
    props: true
  },
  {
    path: '/plan',
    name: 'plan',
    component: () => import('../views/plan.vue'),
    props: true
  },
  {
    path: '/plan_list/:id',
    name: 'planlist',
    component: () => import('../views/plan.vue'),
    props: true
  },
  {
    path: '/plan_info/:id',
    name: 'planinfo',
    component: () => import('../views/planinfo.vue'),
    props: true
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },
  {
    path: '/radio',
    name: 'radio',
    component: () => import('../views/radio.vue')
  },
  {
    path: '/radio_info/:id',
    name: 'radio_info',
    component: () => import('../views/radioinfo.vue')
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import('../views/cases.vue')
  },
]

const router = createRouter({
  // 使用 hash 模式构建路由（ url中带 # 号的那种)
  history: createWebHistory(),
  // 使用 history 模式构建路由 （ url 中没有 # 号，但生产环境需要特殊配置）
  // history: createWebHistory(),
  routes
})
export default router